import React from 'react';
import { WeeklyPrayerTime } from '../utils/types';
import { format } from 'date-fns';
import { FiInfo } from 'react-icons/fi';

interface WeeklyPrayerTimesProps {
  weeklyPrayerTimes: WeeklyPrayerTime | null;
  isLoading: boolean;
}

const WeeklyPrayerTimes: React.FC<WeeklyPrayerTimesProps> = ({ weeklyPrayerTimes, isLoading }) => {
  if (isLoading) {
    return <div className="text-center text-white text-lg">Laddar veckans bönetider...</div>;
  }

  if (!weeklyPrayerTimes) {
    return <div className="text-center text-white text-lg">Inga bönetider tillgängliga för denna vecka.</div>;
  }

  const { week_start, week_end, fajr, dhuhr, asr, maghrib, isha, djuma_khutba, djuma_prayer } = weeklyPrayerTimes;

  const formatDateRange = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return `${format(startDate, 'd MMMM')} - ${format(endDate, 'd MMMM')}`;
  };

  return (
    <div className="bg-secondary rounded-lg p-6 text-white">
      <h3 className="text-xl sm:text-2xl font-arial-rounded-bold mb-3">Veckans Församlingsböner i Moskén</h3>
      <h4 className="text-lg sm:text-xl font-arial-rounded mb-4">{formatDateRange(week_start, week_end)}</h4>
      <ul className="space-y-3 font-arial-rounded">
        <li><span className="font-arial-rounded-bold">Fajr:</span> {fajr}</li>
        <li><span className="font-arial-rounded-bold">Dhuhr:</span> {dhuhr}</li>
        <li><span className="font-arial-rounded-bold">Asr:</span> {asr}</li>
        <li><span className="font-arial-rounded-bold">Maghrib:</span> {maghrib}</li>
        <li><span className="font-arial-rounded-bold">Isha:</span> {isha}</li>
        <li><span className="font-arial-rounded-bold">Djuma:</span> {djuma_khutba} (Khutba) och {djuma_prayer} (Djuma bönen)</li>
      </ul>
      <div className="flex items-start bg-primary/20 rounded-lg p-4 mt-6">
        <FiInfo className="w-5 h-5 mr-3 mt-1 flex-shrink-0" />
        <div className="space-y-2 text-sm sm:text-base font-arial-rounded">
          <p>Församlingsböner är gemensamma böner som hålls i moskén.</p>
          <p>"+5 min" betyder att bönen startar 5 minuter efter bönetiden (athan-tiden).</p>
        </div>
      </div>
    </div>
  );
};

export default WeeklyPrayerTimes;